import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../general.service';


import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';
@Component({
  selector: 'app-car-models',
  templateUrl: './car-models.component.html',
  styleUrls: ['./car-models.component.scss']
})
export class CarModelsComponent {
    HomeUrl: any;
    arr: any;
    dataResult: any;
    Cartypedata: any;

    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient) {
        debugger
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;

           
        });
    }

    ngOnInit(): void {
        this.GetCarBrands();

    }
    GetCarBrands() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_VehicleBrand_Curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.dataResult = data
        }, err => {
        })
    }

    getSubCat(data: any) {
        localStorage.setItem("CarModel", data.VehicleBrandName,);
        localStorage.setItem("CarModelID", data.VehicleBrandID,);
        this.router.navigate(['/Vehicleinfo'])
        
    }
}
