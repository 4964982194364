<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Why Choose <span>Cars For Cash</span></h2>
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Simple and Fast Process</h3>
                    <p> Our streamlined process ensures that you can sell your car quickly without the unnecessary paperwork and delays. From evaluation to payment, we've got you covered.</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>Fair and Competitive Offers:</h3>
                    <p>
                         Receive a fair market value for your vehicle. Our team of experts uses industry-leading tools to assess your car's worth, ensuring you get the best deal possible.

                       
                       </p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>Transparent Transactions</h3>
                    <p>
                         No hidden fees or surprises! We believe in transparency, and our team will walk you through every step of the selling process, providing clarity and peace of mind.
                    </p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>Convenience at Your Fingertips</h3>
                    <p>
                         Take advantage of our online platform, allowing you to get an initial estimate, schedule an inspection, and complete the sale—all from the comfort of your home.
                    </p>
                    <span>4</span>
                </div>
            </div>
            <!--<div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h3>We are unique</h3>
                    <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>We are support</h3>
                    <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p>
                    <span>6</span>
                </div>
            </div>-->
        </div>
    </div>
</div>
