<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b style="color:white;">Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Registration</b></li>
            <div>Registration</div>

        </ul>
    </div>
</nav>
<div class="fluid">
    <div class="container">
        <div class="modernForm">
            <div class="imageSection">
                <div class="image">
                    <div class="overlay"></div>
                    <img src="../../assets/img/Sellyoucarimgs/bgremove_logo.png" alt="cars" class="logo" />
                </div>

            </div>
            <div class="contactForm">
                <h1></h1>
                <form [formGroup]="form" (ngSubmit)="onSubmit()" >
                    <div class="form-group name">
                        <div class="iconName"><i class="fa-solid fa-user"></i></div>
                        <input type="text" formControlName="CustmoerName" name="fullName" id="fullName" placeholder="Enter Full Name" required />
                    </div>
                    <div class="form-group name">
                        <input type="text" formControlName="Mobile"  name="mobile" placeholder="Mobile Number" maxlength="10" required />
                        <div class="iconName"><i class="fa-solid fa-phone"></i></div>
                    </div>
                    <div class="form-group name">
                        <input type="email" formControlName="Email" name="email" id="email" placeholder="Email" required />
                        <div class="iconName"><i class="fa-solid fa-envelope"></i></div>
                    </div>
                    <div class="form-group name">
                        <input type="text" formControlName="Password" name="password" id="password" placeholder="Password" required />
                        <div class="iconName"><i class="fa-solid fa-lock"></i></div>
                        <small class="text-danger" *ngIf="form.get('Password').touched">
                            <span *ngIf="form.get('Password').hasError('required')">Password is required</span>
                            <span *ngIf="form.get('Password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</span>
                        </small>
                    </div>
                    <div class="form-group name">
                        <input type="password" formControlName="Conformpassword" name="password" id="password" placeholder="Confirm Password" required />
                        <div class="iconName"><i class="fa-solid fa-lock"></i></div>
                        <small class="text-danger" *ngIf="form.get('Conformpassword').touched">
                            <span *ngIf="form.get('Conformpassword').hasError('required')">Confirm Password is required</span>
                            <span *ngIf="form.get('Conformpassword').hasError('mismatchedPasswords')">Passwords do not match</span>
                        </small>
                    </div>
                    <div class="form-group name">
                        <textarea rows="4" formControlName="Address" cols="70" placeholder="Enter Your Address" class="text"></textarea>
                        <small class="text-danger" *ngIf="form.get('Address').touched">
                            <span *ngIf="form.get('Address').hasError('required')">Address is required</span>
                            <span *ngIf="form.get('Address').hasError('minlength')">Minimum of 6 characters</span>
                        </small>
                    </div>
                    <div class="checkbox">
                        <input type="checkbox" id="checkbox" name="checkbox" required>
                        <label for="checkbox">
                            By signing up, you agree to the
                            <p><a href="#">Term and Service</a></p>
                        </label>
                    </div>
                    <div class="form-group name">
                        <input type="submit" value="Register">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<style>
    .container-fluid {
        width: 84%;
    }
</style>
