<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="main-banner-text">
                    <!--<img src="../../../assets/img/banners/Blue Red Modern Car Wash Service Banner.png" />-->
                    <!--<h4>We Are Creative</h4>
                    <h1>The Best <span>Digital</span> Experience</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Get Started</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>-->

                    <div style="margin-left:-1%;width:102%;">
                        <!--Carousel Wrapper-->
                        <div id="carousel-thumb" class="carousel carousel-thumbnails slide" data-ride="carousel" data-interval="15000">
                            <!--Slides-->
                            <div class="carousel-inner banner_1" role="listbox">
                                <div class="carousel-item active carousel-banner">
                                    <!--<img class="d-block w-100 carousel-img-fit" src="../../../assets/img/banners/BlueRedModernCarWashServiceBanner.png" style="width:200px;height:70%;" alt="First slide" />-->
                                    <img src="../../../assets/img/banners/BlueRedModernCarWashServiceBanner.png" />
                                </div>
                                <div class="carousel-item carousel-banner">
                                    <!--<img class="d-block w-100 carousel-img-fit" src="../../../assets/img/banners/RedBlackModernCarServiceBanner.png" alt="Second slide" />-->
                                    <!--<img src="../../../assets/img/banners/RedBlackModern CarServiceBanner.png" />-->
                                    <img src="../../../assets/img/banners/banner.jpg" />
                                </div>
                                <div class="carousel-item carousel-banner">
                                    <!--<img class="d-block w-100 carousel-img-fit" src="../../../assets/img/banners/RedBlackModernCarServiceBanner.png" alt="Second slide" />-->
                                    <!--<img src="../../../assets/img/banners/RedBlackModern CarServiceBanner.png" />-->
                                    <img src="../../../assets/img/banners/banners.png"/>
                                </div>
                            </div>
                            <!--/.Slides-->
                            <!--Controls-->
                            <a class="carousel-control-prev" href="#carousel-thumb" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon fnt_width btn1 btn-dark" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carousel-thumb" role="button" data-slide="next">
                                <span class="carousel-control-next-icon fnt_width btn1 btn-dark" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                            <!--/.Controls-->
                            <ol class="carousel-indicators carousel-swap-adjuster carousel-banner-indicator ">
                                <li data-target="#carousel-thumb" data-slide-to="0" class="active tile_3">
                                    <!--<div>
                                        <img class="d-block w-100 " src="https://mdbootstrap.com/img/Photos/Others/Carousel-thumbs/img%20(88).jpg" class="img-fluid">
                                        <p>text example</p>
                                    </div>-->
                                </li>
                                <!--<li data-target="#carousel-thumb" data-slide-to="1" class="tile_2">
                                    <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Others/Carousel-thumbs/img%20(121).jpg" class="img-fluid">
                                </li>-->
                                <!--<li data-target="#carousel-thumb" data-slide-to="2" class="tile_1">
                                    <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Others/Carousel-thumbs/img%20(31).jpg" class="img-fluid">
                                </li>-->
                            </ol>
                        </div>
                    </div>
            </div>
        </div>
    </div>
   </div>
</div>
 
<app-welcome></app-welcome>

<!--<app-about></app-about>-->

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<!--<app-funfacts></app-funfacts>-->

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<!--<app-services></app-services>-->

<!--<app-why-we-different></app-why-we-different>-->

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<!--<app-partner></app-partner>-->

<app-subscribe></app-subscribe>

<style>
    @media only screen and (max-width:600px) {
        .carousel-banner {
            max-height: 200px !important;
            min-height: 200px;
            object-fit: cover;
        }
        .main-banner {
            height: 100%;
            padding-top: 175px;
           padding-bottom:0px; 
        }
    }
</style>
