


<nav class="pre-header display">

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-6">
                            <i class="far fa-envelope "></i> <a href="mailto:office@downersgrovemotorsales.com" style="color:white;"><span class="small_font" >&nbsp;office@downersgrovemotorsales.com </span></a>
                        </div>
                        <div class="col-lg-3">

                        </div>
                        <div class="col-lg-2 web">


                            <a href="https://www.facebook.com/profile.php?id=61554302522548&mibextid=nwBsNb" target="_blank" class="fab fa-facebook-f colr"></a>
                            &nbsp; | &nbsp;
                            <a href="https://www.instagram.com/accounts/login/" target="_blank" class="fab fa-instagram colr"></a>
                        </div>
                    </div>
                    
                </div>
                <div class="col-lg-3 web1">
                    <span style="    margin-left: 25%;">
                        <i class="fa-solid fa-phone"></i>&nbsp; <a href="tel:9876543210" style="color:white;"><span class="small_font"> <b>+1 877  999 2277</b></span></a>
                    </span>
                    &nbsp;<a href="https://www.facebook.com/profile.php?id=61554302522548&mibextid=nwBsNb" target="_blank" class="fab fa-facebook-f colr"></a>
                    &nbsp; | &nbsp;
                    <a href="https://www.instagram.com/accounts/login/" target="_blank" class="fab fa-instagram colr"></a>


                </div>
            </div>
        </div>

    </div>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light "
     [class.active]="classApplied"
     [ngClass]="{'sticky': isSticky}">
    <div class="container mtn5">

        <a class="navbar-brand" routerLink="/">
            <img src="../../../../assets/img/Sellyoucarimgs/Logo.jpeg" class="c_logo"/>
            <!--<img src="../../../../assets/icons/icon-152x152.png" style="width:31%" />-->
            <!--<img src="../../../../assets/icons/logo1.jpg" class="img_width ps4 " />-->
        </a>
        <button class="navbar-toggler pe-3" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="about()">About</span></li>
                <li class="nav-item"><span class="nav-link" (click)="info()">Sell Your Car </span></li>
                <li class="nav-item"><span class="nav-link" (click)="contact()">Contact</span></li>
                <!--<li class="nav-item"><span class="nav-link" routerLink="/registration">Registration</span></li>-->
                <!--<li class="nav-item"><span class="nav-link" routerLink="/login">Login</span></li>-->
                <!--<li class="nav-item"><span class="nav-link" routerLink="/login">Login</span></li>
    <li class="nav-item"><span class="nav-link" (click)="logout()">Logout</span></li>-->
                <li class="nav" *ngIf="logindeta==undefined">
                    <div class="nav-item" *ngIf="!isLoggedIn">
                <span class="nav-link" routerLink="/login">Login</span></div>
                </li>

                <li *ngIf="logindeta!=undefined"class="nav-item">
                    <span class="nav-link"style="    font-size: 20px;
    color: #fe0802;">Welcome! {{ logindeta[0].Username }}&nbsp;</span>
                </li>
                <li></li>
                <li class="nav-item" *ngIf="logindeta!=undefined">
                   <span class="nav-link" (click)="logout()">Logout</span>
                </li>

                &nbsp;
                <!--<div class="nav-item dropdown" (mouseover)="showDropdown = true" (mouseleave)="showDropdown = false">
        <span class="nav-link dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-user"></i> Car Info
        </span>

        <ul *ngIf="showDropdown" class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li><a class="dropdown-item" (click)="navigateToCarInfoPage()">Navigate to Car Info Page</a></li>
        </ul>
    </div>-->



            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="about()">About</span></li>
                <li class="nav-item"><span class="nav-link" (click)="info()">Cash for Car</span></li>
                <li class="nav-item"><span class="nav-link" (click)="contact()">Contact</span></li>
                <!--<li class="nav-item"><span class="nav-link" routerLink="/registration">Registration</span></li>-->
                <li class="nav" *ngIf="logindeta==undefined">
                    <div class="nav-item" *ngIf="!isLoggedIn">
                        <span class="nav-link" routerLink="/login">Login</span>
                    </div>
                </li>

                <li *ngIf="logindeta!=undefined" class="nav-item">
                    <span class="nav-link" style="    font-size: 20px;
    color: #fe0802;">Welcome!{{ logindeta[0].Username }}&nbsp;</span>
                </li>
                <li></li>
                <li class="nav-item" *ngIf="logindeta!=undefined">
                    <span class="nav-link" (click)="logout()">Logout</span>
                </li>

            </ul>
        </div>
    </div>
</nav>
