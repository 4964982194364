<footer class="footer-area">

  
    <!--<div class="contact-icons">
        <a href="mailto:office@downersgrovemotorsales.com" target="_blank" title="Email">
            <i class="fas fa-envelope black"></i>
        </a>

        <a href="tel: +1 877 4 999 2277" target="_blank" title="Phone">
            <i class="fas fa-phone black"></i>
        </a>

        <a href="https://wa.me/ +1 877 4 999 2277" target="_blank" title="WhatsApp">
            <i class="fab fa-whatsapp black"></i>
        </a>
    </div>-->
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <h3 class="quick1">Cars For Cash </h3>
                <p>Our streamlined process ensures that you can sell your car quickly without the unnecessary paperwork and delays. From evaluation to payment, we've got you covered.</p>
            </div>
            <div class="col-lg-4">
                <h3 class="quick">Quick Links</h3>
                <div><a href="about">About Us</a></div>
                <div><a href="CarTypes">Cars For Cash</a></div>
                <div><a href="registration">Registration</a></div>
                <div><a href="login">Login</a></div>
                <div><a href="Contact">Contact Us</a></div>
                <a href="../../../../sellyourcarprivacypolacy.html"target="_blank">Privacy</a>
                <!--<div><a href="sellyourcarprivacypolacy.html" target="_blank">Privacy policy</a></div>-->
                <div><a href="../../../../syctermsandcondition.html" target="_blank">Terms and conditions</a></div>
            </div>
            <div class="col-lg-4">
                <h3 class="quick1">Our Address</h3>
                <p>
                 
                    700 Ogden Ave<br />
                    Downers Grove, IL 60515, USA

                </p>
                <ul>
                    <li><a href="https://www.facebook.com/profile.php?id=61554302522548&mibextid=nwBsNb" target="_blank" class="fab fa-facebook-f"></a></li>
                    <li><a href="https://www.instagram.com/accounts/login/" target="_blank" class="fab fa-instagram"></a></li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <p>All Rights Reserved By <a href="" target="_blank">© Cars For Cash</a></p>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <p>Powered by <a href="https://gagri.net/" target="_blank">Gagri Global IT Services</a></p>
            </div>
        </div>
    </div>
</footer>

<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>
