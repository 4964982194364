
<div class="container">
    <div class="row">
        
        <div class="col-lg-12">
            <div class="container">


                <div class="timeline-container" [ngClass]="{'completed-timeline': isTimelineComplete}">

                    <ul class="timeline">
                        <li class="font_size" *ngFor="let item of dataResult; let i = index" [ngClass]="{'completed' : i < currentCategoryIndex,'line-completed': i < currentCategoryIndex - 2,'green-line': i <= currentCategoryIndex - 1 && currentCategoryIndex !== 0               }">
                            {{ item.VDSName }}<br /><i class="checkmark" *ngIf="item.isCompleted"></i>
                            <!--<i class="fa-solid fa-car-side" *ngIf="i === currentCategoryIndex - 1" style="color:red;font-size: 30px;
                        padding-left: 5%;"></i>-->
                        </li>
                    </ul>
                </div>
            </div>
        </div>





        <div class="container">
            <!-- First Card -->
            <div class="row" *ngIf="currentCardIndex === 0">
                <!-- Content for the first card -->

                <div class="row">
                    <div class="col-lg-12" style="margin-left:2%">
                        <h4 style="color: black;">{{Quationdata[0].QName}}</h4>
                        <p><b>Type<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Type..." [(ngModel)]="cartype" [disabled]="isDisabled" /><br />

                        <p><b>Brand<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Brands..." [(ngModel)]="carStyle" [disabled]="isDisabled" /><br />
                        <p><b>Vehicle Number<span style="color:red"></span></b></p>
                        <input type="text" placeholder="Enter Vehicle Number..." [(ngModel)]="VehicleNumber" /><br />
                        <p><b>Milage(Miles)<span style="color:red"></span></b></p>
                        <input type="text" placeholder=" Enter Car Milage.." [(ngModel)]="Milage" />
                        <div style="border-bottom:1px solid gray;    margin-top: 2%;"></div>

                    </div>
                </div>
                <!-- ... -->
                <br />

                <div class="row">
                    <div class="col-lg-1 col-md-6 col-sm-6 col-6"></div>
                    <div class="col-lg-4 col-md-6 col-sm-6 col-6">
                        <button type="button" class="back" (click)="carbrand()">Back</button>

                    </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                            <button type="button" class="next" (click)="showNextCard1()" [disabled]="!areAllFieldsEntered()">Next</button>
                        </div>


                    </div>


                </div>

            <!-- Second Card -->
            <div class="row" *ngIf="currentCardIndex === 1">

                <div class="row">

                    <div class="col-lg-1"></div>
                    <div class="col-lg-11">
                        <h5 class="gradient-text">Color & available choices</h5>
                        <h4 style="color: black;">{{Quationdata[1].QName}}</h4>
                        <div class="row">


                            <div class="col-lg-2 col-md-6 col-sm-6 col-6">
                                <div (click)="selectOption('1')" [ngClass]="{'selected1': selectedItem === '1'}">
                                    <div class="pantone-card" style="background: black;">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[0].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-6  col-6">
                                <div (click)="selectOption('2')" [ngClass]="{'selected1': selectedItem === '2'}">
                                    <div class="pantone-card" style="background: rgb(12, 29, 56);">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[1].OptionName}}</p>

                                </div>
                            </div>
                            <!-- Add more similar code for other options -->


                            <div class="col-lg-2 col-md-6 col-sm-6  col-6 pt5">
                                <div (click)="selectOption('3')" [ngClass]="{'selected1': selectedItem === '3'}">
                                    <div class="pantone-card" style="background-color: rgb(86, 0, 18)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[2].OptionName}}</p>

                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div (click)="selectOption('4')" [ngClass]="{'selected1': selectedItem === '4'}">
                                    <div class="pantone-card" style="background-color: rgb(238, 234, 217)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[3].OptionName}}</p>

                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div (click)="selectOption('5')" [ngClass]="{'selected1': selectedItem === '5'}">
                                    <div class="pantone-card" style="background-color: rgb(12, 25, 20)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[4].OptionName}}</p>

                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div (click)="selectOption('6')" [ngClass]="{'selected1': selectedItem === '6'}">
                                    <div class="pantone-card" style="background-color: rgb(201, 205, 206)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[5].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div (click)="selectOption('7')" [ngClass]="{'selected1': selectedItem === '7'}">
                                    <div class="pantone-card" style="background-color:rgb(17, 22, 34)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[6].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div (click)="selectOption('8')" [ngClass]="{'selected1': selectedItem === '8'}">
                                    <div class="pantone-card" style="background-color:rgb(161, 161, 159)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[7].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div (click)="selectOption('9')" [ngClass]="{'selected1': selectedItem === '9'}">
                                    <div class="pantone-card" style="background-color: rgb(14, 14, 14)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[8].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div (click)="selectOption('10')" [ngClass]="{'selected1': selectedItem === '10'}">
                                    <div class="pantone-card" style="background-color: rgb(210, 208, 193)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[9].OptionName}}</p>

                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div (click)="selectOption('11')" [ngClass]="{'selected1': selectedItem === '11'}">
                                    <div class="pantone-card" style="background-color: rgb(54, 57, 56)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[10].OptionName}}</p>
                                </div>
                            </div>

                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div (click)="selectOption('94')" [ngClass]="{'selected1': selectedItem === '94'}">
                                    <div class="pantone-card">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">Others</p>
                                </div>
                            </div>
                        </div>

                    </div>



                    <div class="col-lg-1"></div>
                    <div class="col-lg-11">
                        <h4 style="color: black;">{{Quationdata[2].QName}}</h4>
                        <!--<h4 style="color: black;">{{Quationdata[2].QName}}</h4>-->
                        <div class="row">

                            <div class="col-lg-2 col-6">
                                <div (click)="selectingoptions('12')" [ngClass]="{'selected1': selectedItem143 === '12'}">
                                    <div class="pantone-card" style="background:rgb(27, 28, 32)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[0].OptionName}}</p>
                                </div>
                            </div>


                            <div class="col-lg-2 col-6">
                                <div (click)="selectingoptions('13')" [ngClass]="{'selected1': selectedItem143 === '13'}">
                                    <div class="pantone-card" style="background: rgb(174, 8, 31)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[1].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div (click)="selectingoptions('14')" [ngClass]="{'selected1': selectedItem143 === '14'}">
                                    <div class="pantone-card" style="background-color: rgb(81, 61, 60)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[2].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div (click)="selectingoptions('15')" [ngClass]="{'selected1': selectedItem143 === '15'}">
                                    <div class="pantone-card" style="background-color: rgb(232, 216, 190)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[3].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div (click)="selectingoptions('16')" [ngClass]="{'selected1': selectedItem143 === '16'}">
                                    <div class="pantone-card" style="background-color: rgb(27, 28, 32)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[4].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div (click)="selectingoptions('17')" [ngClass]="{'selected1': selectedItem143 === '17'}">
                                    <div class="pantone-card" style="background-color: rgb(131, 130, 136)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[5].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div (click)="selectingoptions('18')" [ngClass]="{'selected1': selectedItem143 === '18'}">
                                    <div class="pantone-card" style="background-color:rgb(81, 61, 60)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[6].OptionName}}</p>

                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div (click)="selectingoptions('19')" [ngClass]="{'selected1': selectedItem143 === '19'}">
                                    <div class="pantone-card" style="background-color:rgb(232, 216, 190)">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">{{Colors[7].OptionName}}</p>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div (click)="selectingoptions('95')" [ngClass]="{'selected1': selectedItem143 === '95'}">
                                    <div class="pantone-card">
                                        <div class="color"></div>
                                    </div>
                                    <p class="color_name">Other</p>
                                </div>
                            </div>


                        </div>
                        <br />
                        <h4 class=" pt-5" style="color: black;">{{Quationdata[3].QName}}</h4>
                        <div class="row">
                            <div class="col-lg-3 " *ngFor="let item of Interiorsdata" (click)="toggleSelection(item)" [ngClass]="{'selected': item.selected}">
                                <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.OptionName}}</div>
                            </div>
                        </div>
                        <div style="border-bottom:1px solid gray;    margin-top: 3%;"></div>
                    </div>


                    <br />


                </div><br />
                <!-- ... -->
                <div class="row">
                    <div class="col-lg-1"></div>
                    <br />
                    <div class="col-lg-3 col-md-6 col-sm-6 col-6 col-xs-6">
                        <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                    </div>
                    <div class="col-lg-3  col-md-6 col-sm-6 col-6 col-xs-6">
                        <button type="button" class="next" (click)="showNextCard1()">Next</button>
                    </div>

                </div>
            </div>

            <!-- Third Card -->
            <div class="row " *ngIf="currentCardIndex === 2">
                <div class="col-lg-1"></div>
                <div class="col-lg-11 ">

                    <div class="row">
                        <div class="col-lg-9">
                            <h5 class="gradient-text">Vehicle history</h5>
                            <h4 style="color: black;">{{Quationdata[4].QName}}</h4>
                            <div class="row">
                                <div class="col-lg-2 col-3" *ngFor="let item of items" (click)="toggleSelection1(item)" [ngClass]="{'selected': item.selected}">
                                    <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3"></div>

                    </div>
                    <!--------------Q5---------------------->


                    <div class="row">
                        <h4 style="color: black;">{{Quationdata[5].QName}}</h4>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-3" (click)="Q4('44')" [ngClass]="{'selected': Q === '44'}">
                            <div class="chip1" [ngStyle]="Qua('44')">
                                NO
                            </div>
                        </div>
                        <div class="col-lg-2 col-3" (click)="Q4('43')" [ngClass]="{'selected': Q === '43'}">
                            <div class="chip1" [ngStyle]="Qua('43')">
                                Yes
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div *ngIf="Q === '43'">
                            <div class="row sub">

                                <div class="col-lg-11 ">
                                    <p>
                                        <b> {{Subquation[0].SQName}}</b>
                                    </p>
                                </div>

                                <div class="col-lg-2 col-3 ">
                                    <div class="chip1" (click)="selectOption1('2')" [ngStyle]="getChipStyle1('2')">No</div>
                                </div>
                                <div class="col-lg-2 col-3 ">
                                    <div class="chip1" (click)="selectOption1('1')" [ngStyle]="getChipStyle1('1')">Yes</div>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="Q === '44'">
                            <div class="row sub">

                                <div class="col-lg-11 ">
                                    <p>
                                        <b> {{Subquation[1].SQName}}</b>
                                    </p>
                                </div>



                                <div class="col-lg-2 col-3">
                                    <div class="chip1" (click)="Sq1('3')" [ngStyle]="LLL('3')">Loan</div>
                                </div>
                                <div class="col-lg-2 col-3 ">
                                    <div class="chip1" (click)="Sq1('4')" [ngStyle]="LLL('4')">Lease</div>
                                </div>
                                <div class="col-lg-2 col-3  ">
                                    <div class="chip1" (click)="Sq1('5')" [ngStyle]="LLL('5')">Lien</div>
                                </div>
                                <div class="col-lg-5 "></div>


                                <div class="col-lg-10 " style="margin-top: 30px;">
                                    <b>Amount Owed</b><br />
                                    <input type="number" placeholder="Amount Owed" />
                                </div>
                            </div>
                        </div>




                    </div>
                    <div class="row">
                        <h4 style="color: black;">{{Quationdata[6].QName}}</h4>
                    </div>
                    <div class="row">

                        <div class="col-lg-2 col-3" (click)="selectaccident('45')" [ngClass]="{'selected': selectedAcc === '45'}">
                            <div class="chip1" [ngStyle]="getAccStyle1('45')">
                                No
                            </div>
                        </div>
                        <div class="col-lg-2 col-3" (click)="selectaccident('46')" [ngClass]="{'selected': selectedAcc === '46'}">
                            <div class="chip1" [ngStyle]="getAccStyle1('46')">
                                Yes
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="selectedAcc === '46'">
                            <div class="row sub">

                                <div class="col-lg-12 ">
                                    <p>
                                        <b> {{Subquation[2].SQName}}</b>
                                    </p>
                                    <p style="color: black;margin-top: -8px;font-size: smaller;">Example: Fender benders, bumper damage, etc. to exterior panels</p>

                                </div>

                                <div class="col-lg-2 col-2">
                                    <div class="chip1" (click)="selectaccident1('6')" [ngStyle]="getAccStyle2('6')">0</div>
                                </div>
                                <div class="col-lg-2 col-2 ">
                                    <div class="chip1" (click)="selectaccident1('7')" [ngStyle]="getAccStyle2('7')">1</div>
                                </div>
                                <div class="col-lg-2 col-2 ">
                                    <div class="chip1" (click)="selectaccident1('8')" [ngStyle]="getAccStyle2('8')">2</div>
                                </div>
                                <div class="col-lg-2 col-2 ">
                                    <div class="chip1" (click)="selectaccident1('9')" [ngStyle]="getAccStyle2('9')">3+</div>
                                </div>
                                <div class="col-lg-7"></div>


                                <div class="col-lg-12 ">
                                    <p>
                                        <b> {{Subquation[3].SQName}}</b>
                                    </p><p style="color: black;margin-top: -8px;font-size: smaller;">Example: Multiple exterior panels damaged, engine or suspension damage, airbags deployed, etc.</p>
                                </div>

                                <div class="col-lg-2 col-2 ">
                                    <div class="chip1" (click)="selectpanel('10')" [ngStyle]="getpanel('10')">0</div>
                                </div>
                                <div class="col-lg-2 col-2 ">
                                    <div class="chip1" (click)="selectpanel('11')" [ngStyle]="getpanel('11')" [ngClass]="{'selected': selectedpanel === '11'}">1</div>
                                </div>
                                <div class="col-lg-2 col-2 ">
                                    <div class="chip1" (click)="selectpanel('12')" [ngStyle]="getpanel('12')" [ngClass]="{'selected': selectedpanel === '12'}">2</div>
                                </div>
                                <div class="col-lg-2 col-2 ">
                                    <div class="chip1" (click)="selectpanel('13')" [ngStyle]="getpanel('13')" [ngClass]="{'selected': selectedpanel === '13'}">3+</div>
                                </div>
                            </div>

                            <!--<div class="row" *ngIf="selectedpanel==='11'||selectedpanel==='12'||selectedpanel==='13'">
                            <div class="col-lg-1  col-3 "></div>
                            <div class="col-lg-11  col-8">
                                <p>
                                    <b> {{Subquation[4].SQName}}</b>

                                </p>
                            </div>

                            <div class="col-lg-1 col-3"></div>
                            <div class="col-lg-2 col-3">
                                <div class="chip1" (click)="selectpanel1('Yes')" [ngStyle]="getpanel1('Yes')">Yes</div>
                            </div>
                            <div class="col-lg-1 col-2 display"></div>
                            <div class="col-lg-2 col-2">
                                <div class="chip1" (click)="selectpanel1('No')" [ngStyle]="getpanel1('No')">No</div>
                            </div>
                        </div><br />-->

                        </div>
                        <div class="row">
                            <h4 style="color:black;">
                                {{Quationdata[7].QName}}
                            </h4>


                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="cardamage('47')" [ngStyle]="cardamage1('47')" [ngClass]="{'selected': carpenal === '47'}">No</div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="cardamage('48')" [ngStyle]="cardamage1('48')" [ngClass]="{'selected': carpenal === '48'}">Yes</div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="cardamage('49')" [ngStyle]="cardamage1('49')" [ngClass]="{'selected': carpenal === '49'}">Idon'tknow</div>
                            </div>
                        </div>
                    </div>
                    <!-- ... -->

                </div>
                <div class="row">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-11">
                        <div style="border-bottom:1px solid gray;    margin-top: 3%;"></div>
                        <div class="row" style=" margin-top: 20px;">
                            <div class="col-lg-3 col-6 col-xs-6">
                                <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                            </div>
                            <div class="col-lg-3 col-6 col-xs-6">
                                <button type="button" class="next" (click)="showNextCard1()">Next</button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <!-- Fourth Card -->
            <div *ngIf="currentCardIndex === 3">
                <div *ngIf="currentCardIndex === 3" class=" row">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-11 ">
                        <h5 class="gradient-text">Vehicle Condition</h5>
                        <h5 style="" class="gradient-text">A) Exterior condition</h5>
                        <div class="row">
                            <h4 style="color:black;">
                                {{Quationdata[8].QName}}
                            </h4>


                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="Exterior('50')" [ngStyle]="Ex('50')" [ngClass]="{'selected': Ex1 === '50'}">No</div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="Exterior('51')" [ngStyle]="Ex('51')" [ngClass]="{'selected': Ex1 === '51'}">Yes</div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="Exterior('52')" [ngStyle]="Ex('52')" [ngClass]="{'selected': Ex1 === '52'}">Idon'tknow</div>
                            </div>
                        </div>

                        <div class="row">
                            <h4 style="color:black">
                                {{Quationdata[9].QName}}
                            </h4><br /><p style="color:black;">Example: Dents, dings, and/or scratches</p>


                            <div class="col-lg-2 col-xs-6" (click)="bodypanel('54')" [ngClass]="{'selected': bpenel === '54'}">
                                <div class="chip1" [ngStyle]="body('54')">
                                    NO
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6" (click)="bodypanel('53')" [ngClass]="{'selected': bpenel === '53'}">
                                <div class="chip1" [ngStyle]="body('53')">
                                    Yes
                                </div>
                            </div>
                        </div>
                        <div class="row sub" *ngIf="bpenel==='53'">
                            <p>
                                <b> {{Subquation[4].SQName}}</b>

                            </p><p style=" color: black; font-size: smaller;">(Select all that apply)</p>

                            <div class="col-lg-2 col-8 col-xs-6" *ngFor="let item of Suboption" (click)="Q12(item)" [ngClass]="{'selected': item.selected}">
                                <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.SOName}}</div>
                            </div>
                        </div>


                        <div class="row">
                            <h4 style="color:black;">
                                {{Quationdata[10].QName}}
                            </h4><br /><p style="color:black;">Example: Small sized damage or larger  sized damage</p>


                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="smd('56')" [ngClass]="{'selected': sms === '56'}" [ngStyle]="smb('56')">
                                    NO
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="smd('55')" [ngClass]="{'selected': sms === '55'}" [ngStyle]="smb('55')">
                                    Yes
                                </div>
                            </div>
                        </div>

                        <div class="row sub" *ngIf="sms ==='55'">
                            <p>
                                <b> {{Subquation[5].SQName}}</b>

                            </p><p style=" color: black; font-size: smaller;">(Select all that apply)</p>

                            <div class="col-lg-3 col-8 col-xs-6" *ngFor="let item of Suboptiondamage" (click)="SQ3(item)" [ngClass]="{'selected': item.selected}">
                                <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.SOName}}</div>
                            </div>
                        </div>



                        <div class="row">

                            <h4 style=" color: black;"> {{Quationdata[12].QName}}</h4>

                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="smd2('58')" [ngClass]="{'selected': sms1 === '58'}" [ngStyle]="smb2('58')">
                                    NO
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="smd2('57')" [ngClass]="{'selected': sms1 === '57'}" [ngStyle]="smb2('57')">
                                    Yes
                                </div>
                            </div>

                        </div>

                        <div class="row sub" *ngIf="sms1 ==='57'">
                            <p>
                                <b> {{Subquation[6].SQName}}</b>

                            </p><p style=" color: black; font-size: smaller;">(Select all that apply)</p>

                            <div class="col-lg-3 col-8 col-xs-6" *ngFor="let item1 of bodydamage" (click)="SQ4(item1)" [ngClass]="{'selected': item1.selected}">
                                <div class="chip" [ngStyle]="{'background-color': item1.selected ? 'green' : '#f0f0f0', 'color': item1.selected ? 'white' : 'black', 'border-color': item1.selected ? 'green' : '#ccc'}">{{item1.SOName}}</div>
                            </div>
                        </div>


                        <!-- ... -->

                        <br />
                        <h5 style="" class="gradient-text"> B) Interior condition</h5>
                        <div class="row">
                            <h4 style="color:black;">
                                {{Quationdata[14].QName}}
                            </h4>


                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="Interior('66')" [ngStyle]="In('66')" [ngClass]="{'selected': In1 === '66'}">Cloth</div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="Interior('67')" [ngStyle]="In('67')" [ngClass]="{'selected': In1 === '67'}">Leather</div>
                            </div>

                        </div>

                        <div class="row">

                            <h4 style="color:black"> {{Quationdata[15].QName}}</h4>


                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="Interior1('68')" [ngStyle]="In12('68')" [ngClass]="{'selected': Ins === '68'}">No</div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="Interior1('69')" [ngStyle]="In12('69')" [ngClass]="{'selected': Ins === '69'}">Yes</div>
                            </div>
                        </div>



                        <div class="row">

                            <h4 style="color:black"> {{Quationdata[16].QName}}</h4>


                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="inc('70')" [ngStyle]="Inc1('70')" [ngClass]="{'selected': Inn === '70'}">No</div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="inc('71')" [ngStyle]="Inc1('71')" [ngClass]="{'selected': Inn === '71'}">Yes</div>
                            </div>
                        </div>
                        <br />

                        <h5 style="" class="gradient-text">C) Mechanical condition</h5>
                        <div class="row">
                            <h4 style="color:black;">
                                {{Quationdata[18].QName}}
                            </h4>


                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="mc('74')" [ngClass]="{'selected': mcn === '74'}" [ngStyle]="mn('74')">
                                    No
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6">
                                <div class="chip1" (click)="mc('75')" [ngClass]="{'selected': mcn === '75'}" [ngStyle]="mn('75')">
                                    Yes
                                </div>
                            </div>
                        </div>

                        <div class="row sub" *ngIf="mcn ==='75'">
                            <p>
                                <b> {{Subquation[8].SQName}}</b>


                                <div class="col-lg-3 col-8 col-xs-6" *ngFor="let item of mcdata" (click)=" SQ9(item)" [ngClass]="{'selected': item.selected}">
                                    <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.SOName}}</div>
                                </div>
                        </div>
                        <div class="row sub" *ngIf="mcn ==='75'">
                            <p>
                                <b> {{Subquation[9].SQName}}</b>

                                <div class="col-lg-3 col-8 col-xs-6" *ngFor="let item of mcdata1" (click)=" SQ10(item)" [ngClass]="{'selected': item.selected}">
                                    <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.SOName}}</div>
                                </div>
                                <br />



                        </div>



                        <div class="row">
                            <h4 style="color:black">
                                {{Quationdata[19].QName}}
                            </h4><br />

                            <div class="col-lg-2 col-xs-6" (click)="fluiedleaks('76')" [ngClass]="{'selected': leakpenel === '76'}">
                                <div class="chip1" [ngStyle]="leaks('76')">
                                    No
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6" (click)="fluiedleaks('77')" [ngClass]="{'selected': leakpenel === '77'}">
                                <div class="chip1" [ngStyle]="leaks('77')">
                                    Yes
                                </div>
                            </div>
                        </div>
                        <div class="row sub" *ngIf="leakpenel==='77'">
                            <p>
                                <b> {{Subquation[12].SQName}}</b>

                                <div class="col-lg-2 col-8 col-xs-6" *ngFor="let item of leakdata" (click)="SQ13(item)" [ngClass]="{'selected': item.selected}">
                                    <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.SOName}}</div>
                                </div>

                        </div>


                        <div class="row">
                            <h4 style="color:black">
                                {{Quationdata[20].QName}}
                            </h4><br />

                            <div class="col-lg-2 col-xs-6" (click)="fluiedleaks1('78')" [ngClass]="{'selected': leakpenel1 === '78'}">
                                <div class="chip1" [ngStyle]="leaks1('78')">
                                    No
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6" (click)="fluiedleaks1('79')" [ngClass]="{'selected': leakpenel1 === '79'}">
                                <div class="chip1" [ngStyle]="leaks1('79')">
                                    Yes
                                </div>
                            </div>
                        </div>
                        <div class="row sub" *ngIf="leakpenel1==='79'">
                            <p>
                                <b> {{Subquation[13].SQName}}</b>

                                <div class="col-lg-2 col-8 col-xs-6" *ngFor="let item of leakdata1" (click)="SQ14(item)" [ngClass]="{'selected': item.selected}">
                                    <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.SOName}}</div>
                                </div>
                        </div>


                        <!-- ... -->
                        <div style="border-bottom:1px solid gray;    margin-top: 3%;"></div>
                        <div class="row" style=" margin-top: 20px;">
                            <div class="col-lg-3 col-6 col-xs-6">
                                <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                            </div>
                            <div class="col-lg-3 col-6 col-xs-6">
                                <button type="button" class="next" (click)="showNextCard1()">Next</button>
                            </div>

                        </div>


                    </div>
                </div>



            </div>

            <!-- Fifth Card  ADDITION INFO-->
            <div class="row " *ngIf="currentCardIndex === 4">
                <div class="col-lg-1"></div>
                <div class="col-lg-11 ">
                    <h5 class="gradient-text">Additional Information</h5>

                    <div class="row">
                        <h4 style="color:black;">
                            {{Quationdata[21].QName}}
                        </h4>


                        <div class="row" style="margin-top: 8px;">

                            <div class="col-lg-2 col-xs-6" (click)="AddIfo('80')" [ngClass]="{'selected': info === '80'}">
                                <div class="chip1" [ngStyle]="adin('80')">
                                    0
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6" (click)="AddIfo('81')" [ngClass]="{'selected': info === '81'}">
                                <div class="chip1" [ngStyle]="adin('81')">
                                    1
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6" (click)="AddIfo('82')" [ngClass]="{'selected':info === '82'}">
                                <div class="chip1" [ngStyle]="adin('82')">
                                    2+
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h4 style="color:black">
                            {{Quationdata[22].QName}}
                        </h4><br />
                        <div class="row">

                            <div class="col-lg-2 col-xs-6" (click)="Q23('83')" [ngClass]="{'selected': info1 === '83'}">
                                <div class="chip1" [ngStyle]="q23('83')">
                                    No
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6" (click)="Q23('84')" [ngClass]="{'selected': info1 === '84'}">
                                <div class="chip1" [ngStyle]="q23('84')">
                                    Yes
                                </div>
                            </div>
                            <div class="col-lg-2 col-xs-6" (click)="Q23('85')" [ngClass]="{'selected':info1 === '85'}">
                                <div class="chip1" [ngStyle]="q23('85')">
                                    I don'tknow
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <h4 style="color:black">
                            {{Quationdata[23].QName}}
                        </h4><br />


                        <div class="col-lg-3 col-8 col-xs-6" *ngFor="let item of Addoptionsdata" (click)="Q25(item)" [ngClass]="{'selected': item.selected}">
                            <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.OptionName}}</div>

                        </div>



                    </div>


                    <div class="row">
                        <h4 style="color:black">
                            {{Quationdata[24].QName}}
                        </h4><br />

                        <div class="col-lg-2 col-xs-6" (click)="wheel('90')" [ngClass]="{'selected': wel === '90'}">
                            <div class="chip1" [ngStyle]="wel1('90')">
                                No
                            </div>
                        </div>
                        <div class="col-lg-2 col-xs-6" (click)="wheel('91')" [ngClass]="{'selected': wel === '91'}">
                            <div class="chip1" [ngStyle]="wel1('91')">
                                Yes
                            </div>
                        </div>
                    </div>
                    <div class="row sub" *ngIf="wel==='91'">


                        <p><b> {{Subquation[15].SQName}}</b></p>
                        <br />
                        <div class="row">
                            <div class="col-lg-3 col-8 col-xs-6" *ngFor="let item of wheelsdata" (click)="SQ15(item)" [ngClass]="{'selected': item.selected}">
                                <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.SOName}}</div>

                            </div>


                        </div>
                    </div>





                    <div class="row">
                        <h4 style="color:black">
                            {{Quationdata[25].QName}}
                        </h4><br />

                        <div class="col-lg-2 col-xs-6" (click)="tair('92')" [ngClass]="{'selected': tair1 === '92'}">
                            <div class="chip1" [ngStyle]="tai1('92')">
                                No
                            </div>
                        </div>
                        <div class="col-lg-2 col-xs-6" (click)="tair('93')" [ngClass]="{'selected': tair1 === '93'}">
                            <div class="chip1" [ngStyle]="tai1('93')">
                                Yes
                            </div>
                        </div>
                    </div>
                    <div class="row sub" *ngIf="tair1==='93'">


                        <p><b> {{Subquation[16].SQName}}</b></p>
                        <br />
                        <div class="row">
                            <div class="col-lg-3 col-8 col-xs-6" *ngFor="let item of tairsdata" (click)="SQ16(item)" [ngClass]="{'selected': item.selected}">
                                <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.SOName}}</div>

                            </div>

                        </div>
                    </div>

                    <!--<div class="row">
                    <textarea type="text" placeholder="Enter if any  more issues"></textarea>
                </div>-->
                    <div style="border-bottom:1px solid gray;    margin-top: 3%;"></div>
                    <div class="row" style="">

                        <div class="col-lg-3 col-6 col-xs-6">
                            <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                        </div>
                        <div class="col-lg-3 col-6 col-xs-6">
                            <button type="button" class="next" (click)="showNextCard1()">Next</button>
                        </div>

                    </div>


                </div>

            </div>

            <!-- sixth Card -->
            <div class="row" *ngIf="currentCardIndex === 5">
                <!-- Content for the first card -->

                <div class="row">
                    <div class="col-lg-6">
                        <p><b>Exterior Images<span style="color:red">*</span></b></p>
                        <input type="file" multiple (change)="detectFiles($event)"
                               #input accept="application/pdf,image/*" class="m-img-upload-btn" />

                        <div class="card images-container">
                            <div *ngFor="let image of Exteriorimg; let i=index;" class="m-image-wrapper">
                                <i class="fa fa-times m-delete-img" (click)="removeImage1(i)"></i>
                                <img [attr.src]="image" *ngIf="image" style="cursor:pointer;">
                            </div>
                        </div>
                    </div>



                    <div class="col-lg-6">
                        <p><b>Interior Images<span style="color:red">*</span></b></p>
                        <input type="file" multiple (change)="detectFiles1($event)"
                               #input accept="application/pdf,image/*" class="m-img-upload-btn" />

                        <div class="card images-container">
                            <div *ngFor="let image1 of Interiorimg; let i=index;" class="m-image-wrapper">
                                <i class="fa fa-times m-delete-img" (click)="removeImage2(i)"></i>
                                <img [attr.src]="image1" *ngIf="image1" style="cursor:pointer;">
                            </div>
                        </div>
                    </div>&nbsp;&nbsp;
                    <div class="col-lg-12">
                        <div class="col-lg-6">
                            <p><b>Document Images<span style="color:red">*</span></b></p>
                            <input type="file" multiple (change)="detectFiles2($event)"
                                   #input accept="application/pdf,image/*" class="m-img-upload-btn" />
                            <div class="card images-container">
                                <div *ngFor="let image2 of Documentimg; let i=index;" class="m-image-wrapper">
                                    <i class="fa fa-times m-delete-img" (click)="removeImage3(i)"></i>
                                    <img [attr.src]="image2" *ngIf="image2" style="cursor:pointer;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6"></div>
                    <div style="border-bottom:1px solid gray;    margin-top: 4%;"></div>
                </div>
                <!-- ... -->
                <br />

                <div class="row">

                    <!--<button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>-->
                    <div class="col-lg-3 col-md-6 col-sm-6 col-6 col-xs-6">
                        <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                        <button type="button" class="next" (click)="showNextCard1()" [disabled]="!areAllFieldsEntered()">Next</button>
                    </div>


                </div>


            </div>

            <!-- seventh Card -->
            <div class="row" *ngIf="currentCardIndex === 6">
                <div class="col-lg-1"></div>
                <div class="col-lg-11">
                    <div *ngIf="!logindeta || logindeta.length === 0">
                        <form>
                            <div class="name">
                                <p><b>Name<span style="color:red">*</span></b></p>
                                <input type="text" name="fullName" id="fullName" placeholder="Name" [(ngModel)]="Username" required />
                                <div class="iconName"><i class="fa-solid fa-user"></i></div>
                            </div>
                            <div class="name">
                                <p><b>Mobile Number<span style="color:red">*</span></b></p>
                                <input type="number" name="Number" id="Number" placeholder="Mobile Number" [(ngModel)]="Mobile" maxlength="10" required />
                                <div class="iconName"><i class="fa-solid fa-mobile"></i></div>
                            </div>

                            <div class="name">
                                <p><b>Email<span style="color:red">*</span></b></p>
                                <input type="text" name="email" id="email" placeholder="Email" [(ngModel)]="EMail" required />
                                <div class="iconName"><i class="fa-solid fa-envelope"></i></div>
                            </div>
                            <div class="name">
                                <p><b>Password<span style="color:red">*</span></b></p>
                                <input type="password" name="password" id="password" placeholder="Password" [(ngModel)]="Password" required />
                                <div class="iconName"><i class="fa-solid fa-eye"></i></div>
                            </div>
                            <!--<div class="name">
                            <input type="password" name="password" id="password" placeholder="Confirm Password" required />
                            <div class="iconName"><i class="fa-solid fa-lock"></i></div>
                        </div>-->

                            <div class="name">
                                <p><b>Address<span style="color:red">*</span></b></p>
                                <textarea rows="4" cols="70" placeholder="Enter Your Address" name="Address" id="Address" [(ngModel)]="Address" class="text"></textarea>
                            </div>

                        </form>

                    </div>
                    <h4 *ngIf="!logindeta" style="font-size: 20px; margin-left: -2px;">
                        Your registration was successful.<br />
                        To finish the vehicle information submission, Please click "Submit".

                    </h4>
                    <h4 *ngIf="logindeta" style="font-size: 20px; margin-left: -2px;">

                        To finish the vehicle information submission, Please click "Submit".

                    </h4>
                    <div style="border-bottom:1px solid gray; width: 920px;"></div>
                </div>


                <div class="row" style=" margin-top: 20px;">

                    <div class="col-lg-1"></div>

                    <div class="col-lg-3 col-6 col-xs-6">
                        <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                    </div>
                    <div class="col-lg-3 col-6">

                        <button type="button" class="next" (click)="Regadd()" *ngIf="!logindeta || logindeta.length === 0">Sign in</button>
                    </div>

                    <div class="col-lg-3 col-6 col-xs-6">
                        <button type="button" class="next" (click)="Submitdetails()" *ngIf="logindeta">Submit</button>
                    </div>


                </div>

            </div>


        </div>

    </div>
</div>
