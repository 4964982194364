import { Component, ElementRef, Renderer2, ViewChild  } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { Router, RouterLink } from '@angular/router';
import { ViewportScroller } from '@angular/common';
interface Item {
    name: string;
    value: number;
    selected: boolean;
}
interface YorN {
    name: string;
    value: number;
    selected: boolean;
}
@Component({
    selector: 'app-vehicle-info',
    templateUrl: './vehicle-info.component.html',
    styleUrls: ['./vehicle-info.component.scss']
})
export class VehicleInfoComponent {
    @ViewChild('card1') card1!: ElementRef;
    @ViewChild('card2') card2!: ElementRef;
    HomeUrl: any;
    isTimelineComplete: boolean = false;
    carin: any=[];
    Username: any;
    VehicleNumber: any;
    isDisabled: boolean = true;
    Mobile: any;
    EMail: any;
    Password: any;
    Address: any;
    arr: any;
    dataResult: any;
    Quationdata: any;
    Subquation: any;
    Optionsdata: any;
    currentCategoryIndex =1;
    Cartypedata: string | null = ''; // Assign default or empty string
    typeID: string | null = '';
    CarModel: string | null = '';
    CarmodelID: string | null = '';
    carModel: string = ''; // Property to bind with Car Model input
    cartype: any ;
    carStyle: any ;
    Milage: any;
    Suboption: any;
    selectedItem: string = '';
    selectedItem143: string = '';
    leakpenel: string = '';
    leakpenel1: string = '';
    wel: string = '';
    Acc: string = '';
    Acc1: string = '';
   
    carpenal: string = '';
    Ex1: string = '';
    info1: string = '';
    bpenel: string = '';
    Q: string = '';
   
    selectedex: string = '';
    currentCardIndex: number = 0;
    currentCategoryIndex1: number = 1;
    Colors: any;
    Interiorsdata: any;
    Suboptiondamage: any;
    selectedValue: number | null = null;
    items: Item[] = [
        { name: 'Clean', value: 40, selected: false },
        { name: 'Salvage', value:41, selected: false },
        { name: 'Rebuilt', value: 42, selected: false }
        // Add more items as needed
    ];
    ItemYorN: YorN[] = [
        { name: 'Yes', value: 43, selected: false },
        { name: 'No', value: 44, selected: false },
       
    ];
    sms: string | null = null;
    selectedpanel: string | null = null;
    selectedItem1: string | null = null;
    selectedAcc: string | null = null;
    penel: string = '';
    Ins: string = '';
    Inn: string = '';
    mcn: string = '';
    Inn1: string = '';
    info: string = '';
    iconNames: string[] = ['fa-car', 'fa-bicycle', 'fa-bus', 'fa-truck', 'fa-motorcycle'];

    sms1: string;
    In1: string;
    mcdata: any;
    mccon: any;
    leakdata: any;
    leakdata1: any;
    mcdata1=[];
    Addoptionsdata: any;
    wheelsdata: any;
    tairsdata: any;
    tair1: string = '';
    mccon1: any;
    mccon2: any;
    SQ1: string;
    bodydamage: any;
    logindeta: any;
    SSComment: any;
    logdata: any;
    Cinfo: Storage;
    carDetailsSubmitted = false;
    sendmail: any = []; MailDetails: any;
    loginDet1: any; logindeta123: any;
    localdata: any;
    localreg: any;
    RegId: any;
    ExteriorImages: any;
    InteriorImage: any;
    DocumentImage: any;
    ImageArray: any = [];
    urls: string[] = []; // Array to store uploaded image URLs
    Exteriorimg: string[] = [];
    Interiorimg: string[] = [];
    Documentimg: string[] = [];
    image: any;
    ImageData: any;
    image1: any;
    ImageData1: any;
    image2: any;
    ImageData2: any;
    EImage: any = [];
    IImage: any = []; DImage: any = [];
    maxImages: number = 4;
    maxImages1: number = 2;

    constructor(private renderer: Renderer2, private viewportScroller: ViewportScroller,
        public router: Router, public generalService: GeneralService, public http: HttpClient) {



        this.Cartypedata = localStorage.getItem('Cartype');
        this.typeID = localStorage.getItem('CartypeId');
        this.CarModel = localStorage.getItem('CarModel');
        this.RegId = localStorage.getItem('RegId');
        this.loginDet1 = localStorage.getItem("LoginDetails");
        this.logindeta123 = JSON.parse(this.loginDet1);
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            debugger
            //this.Cartypedata = localStorage.getItem("Cartype");
            //this.logindeta = localStorage.getItem('Logindetals');

           
           
            this.CarmodelID = localStorage.getItem('CarModelID');
            this.VehicleNumber = localStorage.getItem('VehicleNumber');
            this.Milage = localStorage.getItem('Milage');
            this.Username = localStorage.getItem('Username');
            this.Mobile = localStorage.getItem('Mobile');
            
            this.cartype = this.Cartypedata;
            this.carStyle = this.CarModel;
            this.logindeta = JSON.parse(localStorage.LoginDetails)
        });

    }

    ngOnInit(): void {
        this.GetCategories();
        this.GetQuastions();
        this.GetOptions();
        this.GetOptionsai();
        this.GetOptions1();
        this.GetSubQuations();
        this.GetSubOptions();
        this.GetSubOptions1();
        this.Getmc();
        this.Getmc1();
        this.getleaks();
        this.getleaks1();
        this.Getairs();
        this.Getwheelsoptions();
        this.Getbodypanel();
        const storedIndex = localStorage.getItem('currentCardIndex');

        // If the stored value exists and is a valid number, assign it to currentCardIndex
        if (storedIndex !== null && !isNaN(+storedIndex)) {
            this.currentCardIndex = +storedIndex;
            this.currentCategoryIndex = this.currentCardIndex;
            this.setAndRemoveLocalStorage();
          
        }
    }
    // Function to set the 'currentCardIndex' in localStorage and remove it after 80 seconds
   

    setAndRemoveLocalStorage(): void {
        const maxIndex = 7; // Assuming the maximum index is 7
        const currentCardIndex = Number(localStorage.getItem('currentCardIndex'));

        if (!isNaN(currentCardIndex) && currentCardIndex < maxIndex) {
            localStorage.setItem('currentCardIndex', currentCardIndex.toString());

            // Set a timeout to remove 'currentCardIndex' from localStorage after 80 seconds
            setTimeout(() => {
                localStorage.removeItem('currentCardIndex');
            }, 8000); // 8 seconds in milliseconds (8000 milliseconds)
        }
    }

    selectOption1(option: string): void {
        debugger
        if (this.selectedItem1 === option) {
            localStorage.setItem("SQ1", this.selectedItem1);
            // Deselect the option if it's already selected
            this.selectedItem1 = null;
           
        } else {
            // Select the clicked option
            this.selectedItem1 = option;
        }
    }


    areAllFieldsEntered(): boolean {
        debugger
        return this.cartype !== '' && this.carStyle !== '' && this.VehicleNumber !== undefined && this.Milage !== undefined;
    }

    Sq1(option: string): void {
        debugger
        if (this.SQ1 === option) {
            // Deselect the option if it's already selected
            this.SQ1 = null;
            localStorage.setItem("SQ2", this.SQ1);

        } else {
            // Select the clicked option
            this.SQ1 = option;
        }
    }
    LLL(option: string): any {
        return {
            'background-color': this.SQ1 === option ? 'green' : '#f0f0f0',
            'color': this.SQ1 === option ? 'white' : 'black',
            'border-color': this.SQ1 === option ? 'green' : '#ccc'
        };
    }
    Q4(option: string): void {
        localStorage.setItem("Q5", option);
        debugger
        if (this.Q === option) {
            // Deselect the option if it's already selected
            this.Q = null;

        } else {
            // Select the clicked option
            this.Q = option;
        }
    }
   Qua(option: string): any {
        return {
            'background-color': this.Q === option ? 'green' : '#f0f0f0',
            'color': this.Q === option ? 'white' : 'black',
            'border-color': this.Q === option ? 'green' : '#ccc'
        };
    }
    selectaccident(option: string): void {
        localStorage.setItem("Q8", option);
        debugger
        this.selectedAcc = option; // Set the selected option
    }
    selectaccident1(val: string): void {
        debugger
        localStorage.setItem("SQ3", val);
        if (this.Acc === val) {
            // Deselect the option if it's already selected
            this.Acc = null;
        } else {
            // Select the clicked option
            this.Acc = val;
        }
    }
    selectaccident11(val: string): void {
        debugger
        if (this.Acc1 === val) {
            // Deselect the option if it's already selected
            this.Acc1 = null;
        } else {
            // Select the clicked option
            this.Acc1 = val;
        }
    }
    getAccStyle12(option: string): any {
        return {
            'background-color': this.Acc1 === option ? 'green' : '#f0f0f0',
            'color': this.Acc1 === option ? 'white' : 'black',
            'border-color': this.Acc1 === option ? 'green' : '#ccc'
        };
    }
    getAccStyle2(option: string): any {
        return {
            'background-color': this.Acc === option ? 'green' : '#f0f0f0',
            'color': this.Acc === option ? 'white' : 'black',
            'border-color': this.Acc === option ? 'green' : '#ccc'
        };
    }

    getChipStyle1(option: string): any {
        return {
            'background-color': this.selectedItem1 === option ? 'green' : '#f0f0f0',
            'color': this.selectedItem1 === option ? 'white' : 'black',
            'border-color': this.selectedItem1 === option ? 'green' : '#ccc'
        };
    }

    getAccStyle1(option: string): any {
        return {
            'background-color': this.selectedAcc === option ? 'green' : '#f0f0f0',
            'color': this.selectedAcc === option ? 'white' : 'black',
            'border-color': this.selectedAcc === option ? 'green' : '#ccc'
        };
    }



    toggleSelection1(selectedItem) {
        debugger
        localStorage.setItem("Q4", selectedItem.value,);
        this.items.forEach(item => {
            if (item === selectedItem) {
                item.selected = !item.selected;
            } else {
                item.selected = false; // Deselect other items
            }
        });
    }
    YorNtoggle(selectedItem) {
        this.ItemYorN.forEach(YorN => {
            if (YorN === selectedItem) {
                YorN.selected = !YorN.selected;
            } else {
                YorN.selected = false; // Deselect other items
            }
        });
    }
    //showNextCard1(): void {
    //    if (this.currentCardIndex < 6 && this.currentCategoryIndex < 6) {
    //        this.currentCardIndex++;
    //        this.currentCategoryIndex++;

    //        // Perform any other logic based on the updated indices if needed
    //    }
    //}
    showNextCard1(): void {
        console.log('Before Increment: ', this.currentCardIndex, this.currentCategoryIndex);

        if (this.currentCardIndex < 7 && this.currentCategoryIndex < 7) {
            this.currentCardIndex++;
            this.currentCategoryIndex++;

            // Store the updated value in localStorage
            localStorage.setItem('currentCardIndex', this.currentCardIndex.toString());

            // Perform any other logic based on the updated indices if needed
        }

        console.log('After Increment: ', this.currentCardIndex, this.currentCategoryIndex);
    }

    handleChipClick(value: number): void {
        this.selectedValue = value;
    }
    selectOption(option: string): void {
        debugger
        this.selectedItem = option; // Set the selected option
        localStorage.setItem("Q1", this.selectedItem,);
        localStorage.setItem("Q2", this.selectedItem,);
    }


    selectingoptions(option: string): void {
        debugger
        this.selectedItem143 = option; // Set the selected option
      
        localStorage.setItem("Q2", this.selectedItem143,);
    }
    selectexterior(option: string): void {
        this.selectedex = option; // Set the selectexterior option
    }

    // Function to dynamically set chip styles based on selection
    getChipStyle(option: string): any {
        return {
            'background-color': this.selectedItem === option ? 'green' : '#f0f0f0',
            'color': this.selectedItem === option ? 'white' : 'black',
            'border-color': this.selectedItem === option ? 'green' : '#ccc'
        };
    }
    GetCategories() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_VehicleDetailsSteps_Curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.dataResult = data
        }, err => {
        })
    }
    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }
  
    GetQuastions() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_QuastiomMaster_curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.Quationdata = data
        }, err => {
        })
    }

 

    GetOptions() {
        debugger
      
        var UploadFile = new FormData();
        UploadFile.append("Param",'3');
        
        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.Colors = data
          
        }, err => {
        })
    }
    GetOptionsai() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '25');

        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.Addoptionsdata = data

        }, err => {
        })
    }

    
    GetOptions1() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '5');

        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.Interiorsdata = data

        }, err => {
        })
    }

    GetSubOptions() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '5');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {            
            this.Suboption = data
        }, err => {
        })
    }

    GetSubOptions1() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '6');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {         
            this.Suboptiondamage = data
        }, err => {
        })
    }
    Getbodypanel() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '6');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.bodydamage = data
        }, err => {
        })
    }
    GetSubQuations() {
        debugger
     
        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_SubQuestion_Curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.Subquation = data
        }, err => {
        })
    }
   
    toggleSelection(item: any): void {
        debugger
        item.selected = !item.selected;
        localStorage.setItem("Q3", item.OptionId,);

    }
    Q12(item: any): void {
        debugger
        item.selected = !item.selected;
        localStorage.setItem("Q3", item.SOId,);

    }
    checkTimelineCompletion() {

        debugger
        this.isTimelineComplete = this.currentCategoryIndex === this.dataResult.length;
    }
   
    isCategoryComplete(index: number): boolean {
        debugger
        // Add your logic to determine completion status based on the index or other criteria
        // For example:
        // Assume dataResult is an array containing your categories
        // Here, I'm checking if the category at the given index is complete
        return index < this.currentCategoryIndex;
    }
    moveToNextCategory() {
        debugger
        if (this.currentCategoryIndex < this.dataResult.length + 1) {
            this.currentCategoryIndex++;
            this.checkTimelineCompletion(); // Check if timeline is complete after moving to the next category
        }
    }
   
    moveToPreviousCategory() {
        if (this.currentCardIndex > 0) {
            this.currentCardIndex--;
            this.currentCategoryIndex--;
        }
    }

    calculateIconPosition() {
        debugger
        const percentPerCategory = 100 / this.dataResult.length;
        return `${this.currentCategoryIndex * percentPerCategory}%`;
    }

  

    // Example function to move to the next category after a certain delay
    simulateCategoryProgress() {
        for (let i = 0; i < this.dataResult.length; i++) {
            const customDelay = 1000 * (i + 1); // Default delay per category

            // Example: Increase delay for specific indices
            if (i === 2 || i === 6) {
                const increasedDelay = customDelay * 2; // Doubling the delay for certain indices
                setTimeout(() => {
                    this.moveToNextCategory();
                }, increasedDelay);
            } else {
                setTimeout(() => {
                    this.moveToNextCategory();
                }, customDelay);
            }
        }
    }
    selectpanel1(val: string): void {
       // localStorage.setItem("SQ4", val,);
        debugger
        if (this.penel === val) {
            // Deselect the option if it's already selected
            this.penel = null;
        } else {
            // Select the clicked option
            this.penel = val;
        }
    } getpanel1(option: string): any {
        return {
            'background-color': this.penel === option ? 'green' : '#f0f0f0',
            'color': this.penel === option ? 'white' : 'black',
            'border-color': this.penel === option ? 'green' : '#ccc'
        };
    }

    
    selectpanel(val: string): void {
        localStorage.setItem("SQ4", val,);
        this.selectedpanel = val; // Set the selected option
    }


    getpanel(option: string): any {
        return {
            'background-color': this.selectedpanel === option ? 'green' : '#f0f0f0',
            'color': this.selectedpanel === option ? 'white' : 'black',
            'border-color': this.selectedpanel === option ? 'green' : '#ccc'
        };
    }

    cardamage(option: string): void {
        this.carpenal = option; // Set the selected option
        localStorage.setItem("Q9", option);

    }


    cardamage1(option: string): any {
        return {
            'background-color': this.carpenal === option ? 'green' : '#f0f0f0',
            'color': this.carpenal === option ? 'white' : 'black',
            'border-color': this.carpenal === option ? 'green' : '#ccc'
        };
    }


    Exterior(option: string): void {
        this.Ex1 = option; // Set the selected option
        localStorage.setItem("Q10", option);
    }


    Ex(option: string): any {
        return {
            'background-color': this.Ex1 === option ? 'green' : '#f0f0f0',
            'color': this.Ex1 === option ? 'white' : 'black',
            'border-color': this.Ex1 === option ? 'green' : '#ccc'
        };
    }
    bodypanel(option: string): void {
        this.bpenel = option; // Set the selected option
        localStorage.setItem("Q11", option);
        }


    body(option: string): any {
            return {
                'background-color': this.bpenel === option ? 'green' : '#f0f0f0',
                'color': this.bpenel === option ? 'white' : 'black',
                'border-color': this.bpenel === option ? 'green' : '#ccc'
            };
    }
    smd(option: string): void {
        this.sms = option; // Set the selected option
        localStorage.setItem("Q12", option);
    }


    smb(option: string): any {
        return {
            'background-color': this.sms === option ? 'green' : '#f0f0f0',
            'color': this.sms === option ? 'white' : 'black',
            'border-color': this.sms === option ? 'green' : '#ccc'
        };
    }
    smd2(option: string): void {
        this.sms1 = option; // Set the selected option
        localStorage.setItem("Q12", option);
    }


    smb2(option: string): any {
        return {
            'background-color': this.sms1 === option ? 'green' : '#f0f0f0',
            'color': this.sms1 === option ? 'white' : 'black',
            'border-color': this.sms1 === option ? 'green' : '#ccc'
        };
    }


    Interior(option: string): void {
        this.In1 = option; // Set the selected option
        localStorage.setItem("Q16", option);
    }


    In(option: string): any {
        return {
            'background-color': this.In1 === option ? 'green' : '#f0f0f0',
            'color': this.In1 === option ? 'white' : 'black',
            'border-color': this.In1 === option ? 'green' : '#ccc'
        };
    }


    Interior1(option: string): void {
        this.Ins = option; // Set the selected option
        localStorage.setItem("Q17", option);
    }


    In12(option: string): any {
        return {
            'background-color': this.Ins === option ? 'green' : '#f0f0f0',
            'color': this.Ins === option ? 'white' : 'black',
            'border-color': this.Ins === option ? 'green' : '#ccc'
        };
    }
    inc(option: string): void {
        this.Inn = option; // Set the selected option
        localStorage.setItem("Q18", option);
    }


    Inc1(option: string): any {
        return {
            'background-color': this.Inn === option ? 'green' : '#f0f0f0',
            'color': this.Inn === option ? 'white' : 'black',
            'border-color': this.Inn === option ? 'green' : '#ccc'
        };
    }

    inq(option: string): void {
        this.Inn1 = option; // Set the selected option
    }


    Inc11(option: string): any {
        return {
            'background-color': this.Inn1 === option ? 'green' : '#f0f0f0',
            'color': this.Inn1 === option ? 'white' : 'black',
            'border-color': this.Inn1 === option ? 'green' : '#ccc'
        };
    }

   mc(option: string): void {
       this.mcn = option; // Set the selected option
       localStorage.setItem("Q19", option);
    }


    mn(option: string): any {
        return {
            'background-color': this.mcn === option ? 'green' : '#f0f0f0',
            'color': this.mcn === option ? 'white' : 'black',
            'border-color': this.mcn === option ? 'green' : '#ccc'
        };
    }
    Getmc() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '9');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.mcdata = data
        }, err => {
        })
    }
    Getmc1() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '10');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.mcdata1 = data
        }, err => {
        })
    }
       getleaks() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '13');
        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
           this.generalService.PostData(url, UploadFile).then((data: any) => {
               this.leakdata = data
        }, err => {
        })
    }
    getleaks1() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '14');
        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.leakdata1 = data
        }, err => {
        })
    }
    toggleSelection12(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;
        debugger
        this.mccon = item.selected ? item : null; // Set selectedItem based on the selection
    }
    SQ3(item: any) {
       debugger
        // Toggle the selected property of the clicked item
       localStorage.setItem("SQ5", item.SOId);
        item.selected = !item.selected;
        debugger
       // Set selectedItem based on the selection
    }
    SQ4(item1: any) {
       debugger
        // Toggle the selected property of the clicked item
       localStorage.setItem("SQ6", item1.SOId);
        item1.selected = !item1.selected;
        debugger
       // Set selectedItem based on the selection
    }
    SQ9(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;
        localStorage.setItem("SQ5", item.SOId);
        debugger
    }
    SQ10(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;
        localStorage.setItem("SQ5", item.SOId);
        debugger
      
    }
    SQ13(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;
        localStorage.setItem("SQ13", item.SOId);
        debugger
      
    }
    SQ14(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;
        localStorage.setItem("SQ14", item.SOId);
        debugger
       // Set selectedItem based on the selection
    }
    SQ15(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;
        localStorage.setItem("SQ15", item.SOId);
        debugger
        // Set selectedItem based on the selection
    }
  
    SQ16(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;
        localStorage.setItem("SQ16", item.SOId); // Set selectedItem based on the selection
    }

    fluiedleaks(option: string): void {
        this.leakpenel = option; // Set the selected option
        localStorage.setItem("Q21", option);
    }


    leaks(option: string): any {
        return {
            'background-color': this.leakpenel === option ? 'green' : '#f0f0f0',
            'color': this.leakpenel === option ? 'white' : 'black',
            'border-color': this.leakpenel === option ? 'green' : '#ccc'
        };
    }
    fluiedleaks1(option: string): void {
        this.leakpenel1 = option; // Set the selected option
        localStorage.setItem("Q22", option);
    }


    leaks1(option: string): any {
        return {
            'background-color': this.leakpenel1 === option ? 'green' : '#f0f0f0',
            'color': this.leakpenel1 === option ? 'white' : 'black',
            'border-color': this.leakpenel1 === option ? 'green' : '#ccc'
        };
    }


    AddIfo(option: string): void {
        this.info = option; // Set the selected option
        localStorage.setItem("Q23", option);
    }


    adin(option: string): any {
        return {
            'background-color': this.info === option ? 'green' : '#f0f0f0',
            'color': this.info === option ? 'white' : 'black',
            'border-color': this.info === option ? 'green' : '#ccc'
        };
    }

    Q23(option: string): void {
        this.info1 = option; // Set the selected option
        localStorage.setItem("Q23", option);
    }


    q23(option: string): any {
        return {
            'background-color': this.info1 === option ? 'green' : '#f0f0f0',
            'color': this.info1 === option ? 'white' : 'black',
            'border-color': this.info1 === option ? 'green' : '#ccc'
        };
    }
   Q25(item: any) {
        // Toggle the selected property of the clicked item
       item.selected = !item.selected;
       localStorage.setItem("Q25", item.OptionId);
        debugger
       // Set selectedItem based on the selection
    }
    Getwheelsoptions() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '15');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.wheelsdata = data
        }, err => {
        })
    }

    Getairs() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '16');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.tairsdata = data
        }, err => {
        })
    }

    wheel(option: string): void {
        this.wel = option; // Set the selected option
        localStorage.setItem("Q26", option);
    }


    wel1(option: string): any {
        return {
            'background-color': this.wel === option ? 'green' : '#f0f0f0',
            'color': this.wel === option ? 'white' : 'black',
            'border-color': this.wel === option ? 'green' : '#ccc'
        };
    }

   tair(option: string): void {
       this.tair1 = option; // Set the selected option
       localStorage.setItem("Q27", option);
    }


    tai1(option: string): any {
        return {
            'background-color': this.tair1 === option ? 'green' : '#f0f0f0',
            'color': this.tair1 === option ? 'white' : 'black',
            'border-color': this.tair1 === option ? 'green' : '#ccc'
        };
    }


    Regadd() {

        debugger;
        this.arr = []
        this.arr.push({
            RoleId: 2,
            Username: this.Username,
            Mobile: this.Mobile,
            EMail: this.EMail,
            Password: this.Password,
            Address: this.Address,

        });
        localStorage.setItem("Customer", this.Username);
        localStorage.setItem("mobile", this.Mobile);
        localStorage.setItem("Email", this.EMail);
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '1');       
        var url = "api/SYCar/SYC_Reg_User_Curd";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != null) {
                debugger
                if (data && data) {
                    this.logdata=data
                  
                        this.generalService.ShowAlert('SUCCESS', 'Registered successfully Please submit the car details.', 'success');
                    localStorage.setItem("LoginDetails", this.logdata);
                    localStorage.setItem("reg", this.logdata);
                   this.reloadPageWithDelay();

                   
                }
                else if (data == "EXIST") {
                    this.generalService.ShowAlert('EXIST', 'Employee Already EXIST.', 'EXIST');
                }
                else {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
                }
            }
        });
    }

 
    reloadPageWithDelay(): void {
        setTimeout(() => {
            window.location.reload();
        }, 2000); // 3000 milliseconds = 3 seconds
    }

    Submitdetails() {
        debugger;
        this.carin = []; // Clear carin array to start fresh
        const localStorageData = localStorage;
        for (const key in localStorageData) {
            if (Object.prototype.hasOwnProperty.call(localStorageData, key)) {
                const value = localStorageData[key];

                // Identify the type of data based on the key
                if (key.startsWith('Q')) {
                    this.carin.push({
                        RegId: this.logindeta123.RegId || localStorage.reg,
                        VehicleTypeId: this.typeID,
                        VehicleBrandId: this.CarmodelID,
                        VehicleNo: this.VehicleNumber,
                        Milage: this.Milage,
                        //ExteriorImages: this.ExteriorImages,
                        //InteriorImages: this.InteriorImage,
                        //DocumentImages: this.DocumentImage,
                        OptionId: value, // Using the value associated with the key for Q types
                        SSComment: this.SSComment,
                       // Status: true
                    });
                   
                   
                } else if (key.startsWith('SQ')) {
                    this.carin.push({
                        RegId: this.logindeta123.RegId || localStorage.reg,
                        VehicleTypeId: this.typeID,
                        VehicleBrandId: this.CarmodelID,
                        VehicleNo: this.VehicleNumber,
                        Milage: this.Milage,
                     
                        SOId: value, // Using the value associated with the key for SQ types
                        SSComment: this.SSComment,
                        //Status: true
                    });
                   
                    //this.EImage.push({
                    //    ExteriorImages: this.ExteriorImages,
                    //});
                    //this.IImage.push({
                    //    InteriorImages: this.InteriorImage,
                    //});
                    //this.DImage.push({
                    //    DocumentImages: this.DocumentImage,
                    //});
                }
            }
        }

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.carin));
        uploadfile.append("param1", JSON.stringify(this.EImage));
        uploadfile.append("param2", JSON.stringify(this.IImage));
        uploadfile.append("param3", JSON.stringify(this.DImage));
        var url = "api/Values/SYC_AddCar_info";
        this.generalService.PostData(url, uploadfile).then(data => {
            debugger;
            if (data == "SUCCESS") {
                this.generalService.ShowAlert('SUCCESS', 'Car details Submitted Successfully', 'success');
                this.cardetailsmail();
                this.navgateDelay();
            } else {
                this.generalService.ShowAlert(data, 'Something went wrong. Please try again later.', 'error');
            }
        });
    }
    navgateDelay(): void {
        setTimeout(() => {
            this.router.navigate(['/'])
        },2000); // 3000 milliseconds = 3 seconds
    }

    cardetailsmail() {
        debugger
        this.sendmail = [];
        if (this.logindeta123 && this.logindeta123.length > 0) {
            // If logindeta123 is available and not empty, use its data
            this.sendmail.push({
                FarmerName: this.logindeta123[0].Username || localStorage.Customer,
                Mobile: this.logindeta123[0].Mobile || localStorage.mobile,
                EmailID: this.logindeta123[0].EMail || localStorage.Email,
                BrandName: this.CarModel,
                TypeName: this.Cartypedata,
                Vehiclenumber: this.VehicleNumber,

            });
        } else {
            // If logindeta123 is not available or empty, use other data
            this.sendmail.push({
                FarmerName: this.Username || localStorage.Customer,
                Mobile: this.Mobile || localStorage.mobile,
                EmailID: this.EMail || localStorage.Email,
                BrandName: this.CarModel,
                TypeName: this.Cartypedata,
                Vehiclenumber: this.VehicleNumber,
            });
        }
        var uploadfile = new FormData();
        uploadfile.append("Email", JSON.stringify(this.sendmail));
        var url = "api/SYCar/EnquiryMailTo_SYCCustomerCarDetails";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            debugger
            this.MailDetails = data;
            this.generalService.ShowAlert("SUCCESS", 'Your request is rise in mail, our customer support will reach you soon', 'success');          
        })

    }

    removeImage1(index): void {    /////Remove Image
        debugger
        this.Exteriorimg.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    //This is for Uploading Multiple Images
    detectFiles(event) {
        debugger
        //this.urls = [];
        let files = event.target.files;
        if (files && files.length + this.Exteriorimg.length <= this.maxImages) {
            for (let file of files) {
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    debugger
                    this.Exteriorimg.push(e.target.result);
                }
                reader.readAsDataURL(file);
            }
            this.fileChange(event);
        }
        else {
            this.generalService.ShowAlert("warning", 'you can upload only four images', 'warning')
        }
    }
    //This is for Uploading Multiple Images
    fileChange(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/SYCar/UploadExteriorImage';
            this.generalService.PostData(url, frmData).then((data: any) => {
                if (data != null) {

                    this.ImageData = data;
                    this.image = data;
                    this.EImage.push({ ExteriorImages: this.ImageData })
                }
            });
        }

    }


    removeImage2(index): void {    /////Remove Image
        debugger
        this.Interiorimg.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    //This is for Uploading Multiple Images
    detectFiles1(event) {
        debugger
        //this.urls = [];
        let files = event.target.files;
        if (files && files.length + this.Interiorimg.length <= this.maxImages) {
            for (let file of files) {
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    this.Interiorimg.push(e.target.result);
                }
                reader.readAsDataURL(file);
            }
            this.fileChange1(event);

        }
        else {
            this.generalService.ShowAlert("warning", 'you can upload only four images', 'warning')
        }
          
       
    }
    //This is for Uploading Multiple Images
    fileChange1(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/SYCar/UploadInteriorImage';
            this.generalService.PostData(url, frmData).then((data: any) => {
                if (data != null) {

                    this.ImageData1 = data;
                    this.image1 = data;
                    this.IImage.push({ InteriorImages: this.ImageData1 })
                }
            });
        }

    }


    removeImage3(index): void {    /////Remove Image
        debugger
        this.Documentimg.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }

    //This is for Uploading Multiple Images
    detectFiles2(event) {
        debugger
        //this.urls = [];
        let files = event.target.files;
        if (files && files.length + this.Documentimg.length <= this.maxImages1) {
            for (let file of files) {
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    this.Documentimg.push(e.target.result);
                }
                reader.readAsDataURL(file);
            }
            this.fileChange2(event);
        }
        else {
            this.generalService.ShowAlert("warning", 'you can upload only two images', 'warning')
        }
    }
    //This is for Uploading Multiple Images
    fileChange2(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/SYCar/UploadDocumentImage';
            this.generalService.PostData(url, frmData).then((data: any) => {
                if (data != null) {

                    this.ImageData2 = data;
                    this.image2 = data;
                    this.DImage.push({ DocumentImages: this.ImageData2 })
                }
            });
        }

    }

    carbrand() {
        this.router.navigate(['/CarModels'])
    }
 

}


